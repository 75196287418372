// Project operations

import { IOrgProjects, IOrgUser, IProject, IProjectAoi, IProjectListItem, IProjectParcelAttribute, IProjectScenario, IProjectUserSettings, IProjectUserSettingsLayerState, IProjectUserSettingsLayerStates } from "./ProjectInterfaces";
import useStore from "../store";
import { CallServer } from "../CallServer";
import Debug from "../Debug";
import { BaseMap } from "../BaseMapPanel";
import { IMapView } from "../Map/Map";
import { AddLayerToMap, GetLayerFromListByID, GetLayerIndex, RemoveAllLayersAndSourcesFromMap } from "../Layers/LayerOps";
import { LoadAoi, RemoveAoiLayerFromMap } from "../Aois/AoiOps";
import { ToastNotification } from "../ToastNotifications";
import { LoadHbvScenario, ResetHbv } from "../HBV/HbvOps";
import { DEFAULT_BUNDLE_ID, FriendlyDateFromStr } from "../Globals";
import { UpdateMapView } from "../Map/MapOps";
import { ExitParcelsMode } from "../Parcels/ParcelOps";
import { LoadBundle } from "../Bundles/BundleOps";
import { ILayer } from "../Layers/LayerInterfaces";
import { SyncActiveProjectSavedNrrStates } from "../storeOps";
import { arrayMove } from "@dnd-kit/sortable";
import { IProjectSharingOrg } from "./ProjectShare";
import { GetOrg, IOrganization } from "../Account/UserOps";
import { GeoJSON } from 'geojson';
import { AnyLayer } from "mapbox-gl";
import { GetGeojsonExtentBBox } from "../GisOps";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { DEFAULT_PARCEL_ATTRIBUTES } from "../Parcels/ParcelInterfaces";


//-------------------------------------------------------------------------------
// Returns a default project (a new unsaved project with default settings).
//-------------------------------------------------------------------------------
export function DefaultProject(): IProject
{
  //const DEFAULT_BUNDLE_ID: number = process.env.REACT_APP_BUNDLE_ID ? Number.parseInt(process.env.REACT_APP_BUNDLE_ID) : 6;

  const newUnsavedProject: IProject = 
  {
    project_id: null,
    name: 'New Project',
    bundle_id: DEFAULT_BUNDLE_ID,
    user_settings: DefaultProjectUserSettings(),
    nrr_customization: {},
    isDirty: true,
    client_side_load_date: null,
    aoi_group_id: undefined,
    scenario_group_id: undefined,
    aois: [],
    scenarios: [],
    load_date: undefined,
    is_group_shared: false,
    boundary: undefined,
    organization_id: undefined,
  }
  return newUnsavedProject;
}

//-------------------------------------------------------------------------------
// Returns default project user settings.
//-------------------------------------------------------------------------------
export function DefaultProjectUserSettings(): IProjectUserSettings
{
  const newProjectUserSettings: IProjectUserSettings = 
  {
    baseMap: BaseMap.SatelliteStreets,
    mapView: DefaultMapView(),
    lastActiveAoiId: null,
    nrrStates: [],
    layerStates: DefaultProjectUserSettingsLayerStates(),
    parcel_attribute_list: DefaultProjectUserSettingsParcelAttribList(),
    identify_showEmptyValues: true,
    identify_showOnlyParcelUserAttributes: true,
    user_values: []
  }

  return newProjectUserSettings;
}

//-------------------------------------------------------------------------------
// Returns default project user settings: parcel attributes list.
//-------------------------------------------------------------------------------
export function DefaultProjectUserSettingsParcelAttribList(): IProjectParcelAttribute[]
{
  // NOTE: The order matters - attributes will appear in the parcel table viewer in this order

  const attribList: IProjectParcelAttribute[] = [];
  for(let i=0; i < DEFAULT_PARCEL_ATTRIBUTES.length; i++)
    attribList.push({ name: DEFAULT_PARCEL_ATTRIBUTES[i] });

  return attribList;
}

//-------------------------------------------------------------------------------
// Returns default project user settings layer states.
//-------------------------------------------------------------------------------
export function DefaultProjectUserSettingsLayerStates(): IProjectUserSettingsLayerStates
{
  const newProjectUserSettingsLayerStates: IProjectUserSettingsLayerStates = 
  {
    baseMapLabelsEnabled: true,
    baseMapStatesEnabled: true,
    baseMapRoadsEnabled: true,
    layers: [],
  }

  return newProjectUserSettingsLayerStates;
}

//-------------------------------------------------------------------------------
// Returns default map view.
//-------------------------------------------------------------------------------
export function DefaultMapView(): IMapView
{
  const mapView: IMapView =
  {
    lat: 38.9045,
    lng: -99.1862,     // ContUS
    zoom: 3.51,
    bearing: 0,
    pitch: 0
  }

  return mapView;
}

//-------------------------------------------------------------------------------
// Load project.
//-------------------------------------------------------------------------------
export async function LoadProject(projectID: string): Promise<boolean>
{
  if(!projectID)
  {
    Debug.error('ProjectOps.LoadProject> ERROR: null project ID');
    return false;
  }

  // Save the active project if needed (before we load a new project in)
  const store_project: IProject | null = useStore.getState().store_project;
  if(store_project && store_project.project_id && store_project.isDirty)
    await SaveActiveProject();

  PrepAppForProjectSwitch();

  // First load the default bundle.  We need to do this every time a project is
  // loaded because the project's boundary is embedded into each layer url.

  if(await LoadBundle(DEFAULT_BUNDLE_ID, projectID) === false)
    return false;

  // Call the server to get the data

  const server = new CallServer();
  server.Add("project_id", projectID);

  useStore.getState().store_setProjectIsLoading(true);  // Tell the UI we are loading in a project

  const result = await server.Call('get', '/project');

  useStore.getState().store_setProjectIsLoading(false); // Tell the UI we are no longer loading in a project

  if(result.success)
  {
    Debug.log('ProjectOps.LoadProject> API server call SUCCESS');
    //Debug.log('ProjectOps.LoadProject> SUCCESS! data=' + JSON.stringify(result.data));
    
    const loadedProject: IProject = result.data;

    if(!loadedProject.aoi_group_id || !loadedProject.scenario_group_id)
    {
      // Failure
      ToastNotification('error', "Unable to load project (invalid AOI or scenario group)")
      Debug.error(`ProjectOps.LoadProject> Unable to load project - undefined aoi_group_id or scenario_group_id`);
      return false;
    }

    Debug.log(`ProjectOps.LoadProject> aoi_group_id=${loadedProject.aoi_group_id}`);
    Debug.log(`ProjectOps.LoadProject> scenario_group_id=${loadedProject.scenario_group_id}`);

    loadedProject.isDirty = false;
    loadedProject.client_side_load_date = new Date();

    // For now, we ignore the project's bundle ID and force the default bundle ID to be used
    loadedProject.bundle_id = DEFAULT_BUNDLE_ID;

    // Save the date/time this project was loaded in
    //useStore.getState().store_setProjectLoadDate(new Date());

    // Check if the project is missing any items (maybe older projects).
    // If any are missing, add them as defaults.

    if(loadedProject.user_settings === undefined)
      loadedProject.user_settings = DefaultProjectUserSettings();

    if(loadedProject.user_settings.layerStates === undefined)
      loadedProject.user_settings.layerStates = DefaultProjectUserSettingsLayerStates();

    if(loadedProject.user_settings.baseMap === undefined)
      loadedProject.user_settings.baseMap = BaseMap.SatelliteStreets;

    if(loadedProject.user_settings.mapView === undefined)
      loadedProject.user_settings.mapView = DefaultMapView();

    if(loadedProject.user_settings.parcel_attribute_list === undefined || loadedProject.user_settings.parcel_attribute_list.length === 0)
      loadedProject.user_settings.parcel_attribute_list = DefaultProjectUserSettingsParcelAttribList();

    if(loadedProject.user_settings.identify_showEmptyValues === undefined)
      loadedProject.user_settings.identify_showEmptyValues = true;

    if(loadedProject.user_settings.identify_showOnlyParcelUserAttributes === undefined)
      loadedProject.user_settings.identify_showOnlyParcelUserAttributes = true;

    // Reformat scenario dates from '2023-01-23T00:00:00+00:00' to 'Jan 22, 2023'
    // Also initializes 'selected' to FALSE for each scenario.
    for(let i=0; i < loadedProject.scenarios.length; i++)
    {
      loadedProject.scenarios[i].last_run_date = FriendlyDateFromStr(loadedProject.scenarios[i].last_run_date);
      loadedProject.scenarios[i].selected = false;
    }

    // If this was a shared project, and is flagged as NEW, turn off the new tag and mark the project as dirty
    if(loadedProject.user_settings.sharing_info && loadedProject.user_settings.sharing_info.shared_new === true)
    {
      loadedProject.user_settings.sharing_info.shared_new = false;
      //useStore.getState().store_setProjectListShareState(projectID, false);
      useStore.getState().store_setProjectIsDirty(true);
    }

    // Load the boundary string as GeoJSON, and determined its bounding box (will be used for restricting the map view)

    if(loadedProject.boundary)
    {
      //loadedProject.boundary.boundaryBBox = [-87.634938,24.523096,-80.031362,31.000888];  // TEMP FLORIDA
      // NOTE: Comment out the 2 lines below to temporarily not render the project boundary
      loadedProject.boundary.boundaryGeoJSON = JSON.parse(loadedProject.boundary.boundary) as GeoJSON;
      loadedProject.boundary.boundaryBBox = GetGeojsonExtentBBox(loadedProject.boundary.boundaryGeoJSON);

      // Restrict the Mapbox search box to the project boundary's bbox
      const mapboxGeocoderBbox: MapboxGeocoder.Bbox = loadedProject.boundary.boundaryBBox as [number,number,number,number];
      useStore.getState().store_mapSearchControl?.setBbox(mapboxGeocoderBbox);

      // Set the proximity point to the boundary polygon's "center of mass"
      // TURNING THIS OFF FOR NOW (not sure if it's of much help)
      //const centerPoint: number[] = GetPolygonCenterPoint(loadedProject.boundary.boundaryGeoJSON);
      //useStore.getState().store_mapSearchControl?.setProximity({ latitude: centerPoint[0], longitude: centerPoint[1] });
    }

    // By this point we know the UserInfo has already been loaded
    loadedProject.organization = GetOrg(loadedProject.organization_id);

    // Restore the saved active layers from the previous session
    // NOTE: This does not add the active layers into Mapbox - that is done separately
    LoadProjectSettingsLayerStates(loadedProject);

    // Update the state store
    useStore.getState().store_setProject(loadedProject);
    useStore.getState().store_setUserProfileActiveProjectID(projectID);
    useStore.getState().store_setBundleUIMode('default');
    useStore.getState().store_setAoi(null);
    useStore.getState().store_setAoiUIMode('default');

    // This adds new NRRs/impacts to the project, and removes any deleted ones. 
    // It keeps the project settings in sync with the actual NRRs/impacts that currently exist.
    SyncActiveProjectSavedNrrStates(false);

    UpdateMapView();

    // If we have a project boundary, add the special layer for it to the map
    if(loadedProject.boundary)
      AddProjectBoundaryLayerToMap();

    // If this project has at least one saved AOI associated with it, load in an AOI.

    if(loadedProject.aois && loadedProject.aois.length > 0)
    {
      // If the project's 'lastActiveAoiId' is set, load in that AOI
      if(loadedProject.user_settings.lastActiveAoiId)
        LoadAoi(loadedProject.user_settings.lastActiveAoiId, false);
      else  // As a fall-back, load in the first AOI in the list
        LoadAoi(loadedProject.aois[0].aoi_id);
    }


// TEMP FOR TESTING
// TEMP FOR TESTING
// TEMP FOR TESTING

// if(loadedProject.parcel_attributes)
// {
//   for(let i=0; i < loadedProject.parcel_attributes.length; i++)
//   {
//     const parcelAttribute: IParcelAttribute = loadedProject.parcel_attributes[i];
//     if(parcelAttribute.display_name === 'CDL Majority Category')
//     {
//       parcelAttribute.data_type = 'enum';
//       parcelAttribute.enum_list = [ 'Grassland/Pasture', 'Alfalfa', 'Corn', 'Winter Wheat', 'Fallow/Idle Cropland' ];
//     }
//   }
// }
    
// TEMP FOR TESTING
// TEMP FOR TESTING
// TEMP FOR TESTING



    Debug.log(`Projects.LoadProject> Project ${projectID} loaded.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load project")
    Debug.error(`ProjectOps.LoadProject> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Loads the list of projects for this user.
//-------------------------------------------------------------------------------
export async function LoadProjectList(): Promise<boolean>
{
  // Call the server to get the data

  const server = new CallServer();

  // Tell the UI we are loading the project list
  useStore.getState().store_setProjectListIsLoading(true);

  const result = await server.Call('get', '/projects');

  // Tell the UI we are no longer loading the project list
  useStore.getState().store_setProjectListIsLoading(false);

  if(result.success)
  {
    Debug.log('ProjectOps.LoadProjectList> API server call SUCCESS');
    //Debug.log('ProjectOps.LoadProjectList> SUCCESS! data=' + JSON.stringify(result.data));
    
    const newProjectList: IProjectListItem[] = result.data; // Flat list of projects (all orgs)

    // Reorganize the flat list by organization (that's how we need to display it to the user)

    const orgs: IOrgProjects[] = [];

    const projectsWithNoOrg: IOrgProjects = // special group for projects that have no org
    {
      organization_id: -1,
      org_name: 'No Organization',
      projects: []
    }

    for(let u=0; u < newProjectList.length; u++)
    {
      const project = newProjectList[u];

      // Projects without an org go into a special "No Org" group.
      // In theory this should not be needed later on, but it is initially as we
      // transition to this new system (where each project is associated with exactly one org)
      if(!project.organization_id)
      {
        projectsWithNoOrg.projects.push(project);
        continue;
      }

      // If a record for this org doesn't exist yet, create it

      let listOfProjectsForOrg: IOrgProjects | undefined = orgs.find(findOrg => findOrg.organization_id === project.organization_id);
      if(!listOfProjectsForOrg)
      {
        const orgInfo = GetOrg(project.organization_id);
        const orgName = orgInfo ? orgInfo.name : 'No Org Name';
        listOfProjectsForOrg =
        {
          organization_id: project.organization_id,
          org_name: orgName,
          projects: []
        }
        orgs.push(listOfProjectsForOrg);
      }

      // Add the project to this org's list of projects
      listOfProjectsForOrg.projects.push(project);
    }

    // If we have projects without orgs, include them in a special group at the bottom
    if(projectsWithNoOrg.projects.length > 0)
      orgs.push(projectsWithNoOrg);

    // Update the state store
    useStore.getState().store_setOrgProjectsList(orgs);

    // Success
    Debug.log(`ProjectOps.LoadProjectList> Project list loaded (${newProjectList.length} items)`);
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load project list")
    Debug.error(`ProjectOps.LoadProjectList> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Resets various parts of the app to prepare for a project switch.
//-------------------------------------------------------------------------------
function PrepAppForProjectSwitch()
{
  ResetHbv();
  ExitParcelsMode();
  RemoveAllLayersAndSourcesFromMap();
  RemoveProjectBoundaryLayerFromMap();
  RemoveAoiLayerFromMap();
}

//-------------------------------------------------------------------------------
// Create a new project (using the active unsaved project in the state store: store_project).
//-------------------------------------------------------------------------------
export async function CreateNewProject(): Promise<boolean>
{
  const unsavedProject: IProject | null = useStore.getState().store_project;
  if(!unsavedProject)
  {
    Debug.warn('ProjectOps.CreateNewProject> NULL project');
    return false;
  }  

  PrepAppForProjectSwitch();

  // Call the server to create the new project

  const server = new CallServer();
  server.Add("name", unsavedProject.name);
  if(unsavedProject.bundle_id)
    server.Add("bundle_id", unsavedProject.bundle_id);
  server.Add("user_settings", unsavedProject.user_settings);
  if(unsavedProject.organization_id)
    server.Add("organization_id", unsavedProject.organization_id);

  const result = await server.Call('post', '/project');

  if(result.success)
  {
    Debug.log('ProjectOps.CreateNewProject> API server call SUCCESS');
    //Debug.log('ProjectOps.LoadProject> SUCCESS! data=' + JSON.stringify(result.data));
    
    const newProjectID: string = result.data.project_id;
    if(!newProjectID || newProjectID.length <= 0)
    {
      Debug.error('ProjectOps.CreateNewProject> ERROR: Received invalid project ID');
      return false;
    }

    // Set this as the active project in the user's profile
    useStore.getState().store_setUserProfileActiveProjectID(newProjectID);
    useStore.getState().store_setUserProfileIsDirty(true);

    // Load the project (it will get the full bundle of layers with boundary urls, the boundary, etc)
    LoadProject(newProjectID);
/*
    const new_aoi_group_id: number = result.data.aoi_group_id;
    if(!new_aoi_group_id || new_aoi_group_id <= 0)
    {
      Debug.error('ProjectOps.CreateNewProject> ERROR: Received invalid aoi group id');
      return false;
    }

    const new_scenario_group_id: number = result.data.scenario_group_id;
    if(!new_scenario_group_id || new_scenario_group_id <= 0)
    {
      Debug.error('ProjectOps.CreateNewProject> ERROR: Received invalid scenario group id');
      return false;
    }

    // Update the state store

    const savedProject: IProject =
    {
      ...unsavedProject,
      project_id: newProjectID,
      aoi_group_id: new_aoi_group_id,
      scenario_group_id: new_scenario_group_id,
      isDirty: false,
      organization: GetOrg(unsavedProject.organization_id)
    }

    // Load the default bundle
    LoadBundle(DEFAULT_BUNDLE_ID, true);

    //useStore.getState().store_setBundle(null);
    useStore.getState().store_setBundleUIMode('default');
    useStore.getState().store_setProject(savedProject);
    useStore.getState().store_setUserProfileActiveProjectID(newProjectID);
    useStore.getState().store_setUserProfileIsDirty(true);
    useStore.getState().store_setAoi(null);
    useStore.getState().store_setAoiUIMode('default');
*/
    // Auto-open the AOI drawer so the user knows what the next step is
    useStore.getState().store_setActiveDrawerMenuItem('aoi');

    Debug.log(`Projects.CreateNewProject> Project created: id=${newProjectID}`);
    return true;  // success
  }
  else
  {
    if(result.errorMessage === '{"detail":"Project already exists"}')
      ToastNotification('error', "A project with that name already exists")
    else
      ToastNotification('error', "Unable to create a new project")

    Debug.error(`ProjectOps.CreateNewProject> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Save the active project.
//-------------------------------------------------------------------------------
export async function SaveActiveProject(): Promise<boolean>
{
  const activeProject: IProject | null = useStore.getState().store_project;
  if(!activeProject || !activeProject.project_id)
  {
    Debug.warn('ProjectOps.SaveActiveProject> Invalid project or project_id');
    return false;
  }

  UpdateProjectSettingsLayerStates(activeProject);

  // Call the server to save the project

  const server = new CallServer();
  server.Add("project_id", activeProject.project_id);
  server.Add("name", activeProject.name);
  if(activeProject.bundle_id)
    server.Add("bundle_id", activeProject.bundle_id);
  server.Add("user_settings", activeProject.user_settings);
  //server.Add("nrr_customization", activeProject.nrr_customization);
  
  const result = await server.Call('post', '/project');

  if(result.success)
  {
    Debug.log('ProjectOps.SaveActiveProject> API server call SUCCESS');
    //Debug.log('ProjectOps.SaveActiveProject> SUCCESS! data=' + JSON.stringify(result.data));

    // Update the state store
    useStore.getState().store_setProjectIsDirty(false);

    Debug.log(`Projects.SaveActiveProject> Project saved`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to save project")
    Debug.error(`ProjectOps.SaveActiveProject> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Delete the specified project (may or may not be the active project).
//-------------------------------------------------------------------------------
export async function DeleteProject(projectID: string): Promise<boolean>
{
  // Call the server to save the project

  const server = new CallServer();
  server.Add("project_id", projectID);

  ResetHbv();

  const result = await server.Call('delete', '/project');

  if(result.success)
  {
    Debug.log('ProjectOps.DeleteProject> API server call SUCCESS');
    //Debug.log('ProjectOps.DeleteProject> SUCCESS! data=' + JSON.stringify(result.data));

    // Update the state store
    useStore.getState().store_removeProjectFromList(projectID);

    Debug.log(`Projects.DeleteProject> Project deleted`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete project")
    Debug.error(`ProjectOps.DeleteProject> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Load the list of all users that belong to the same organization list as the
// active user.
//
// NOTE: A user can be part of multiple orgs.
//-------------------------------------------------------------------------------
export async function GetOrgUsers(): Promise<boolean>
{
  const store_userInfo = useStore.getState().store_userInfo;
  if(!store_userInfo)
  {
    ToastNotification('error', 'Unable to load user list');
    Debug.error('ProjectOps.GetOrgUsers> Invalid user info');
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id)
  {
    Debug.error('ProjectOps.GetOrgUsers> Invalid project');
    return false;
  }

  // Call the server to get the data

  const server = new CallServer();
  server.Add('project_id', store_project.project_id); // this optional parameter causes only users in the project's org to be returned

  useStore.getState().store_setOrgUsersLoading(true);

  const result = await server.Call('get', '/organization_users');

  useStore.getState().store_setOrgUsersLoading(false);

  if(result.success)
  {
    Debug.log('ProjectOps.GetOrgUsers> API server call SUCCESS');
    //Debug.log('ProjectOps.GetOrgUsers> SUCCESS! data=' + JSON.stringify(result.data));

    const orgUsers: IOrgUser[] = result.data;

    // Reorganize the flat list by organization (that's how we need to display it to the user)

    const orgs: IProjectSharingOrg[] = [];

    for(let u=0; u < orgUsers.length; u++)
    {
      const orgUser = orgUsers[u];

      for(let o=0; o < orgUser.organizations.length; o++)
      {
        const org: IOrganization = orgUser.organizations[o];

        // If a record for this org doesn't exist yet, create it

        let projSharingOrg: IProjectSharingOrg | undefined = orgs.find(findOrg => findOrg.id === org.id);
        if(!projSharingOrg)
        {
          projSharingOrg =
          {
            id: org.id,
            name: org.name,
            users: []
          }
          orgs.push(projSharingOrg);
        }

        // Add the user to this org's user list

        projSharingOrg.users.push(
        {
          id: orgUser.id,
          name: orgUser.first_name + ' ' + orgUser.last_name
        })
      }
    }

    // Update the state store
    useStore.getState().store_setProjectSharingOrgs(orgs);

    // Update the state store
    //useStore.getState().store_setOrgUsers(orgUsers);

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to load user list")
    Debug.error('ProjectOps.GetOrgUsers> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Share the active project with the specified user.
//-------------------------------------------------------------------------------
export async function ShareProject(user_id: string, user_message: string | undefined): Promise<boolean>
{
  const store_userInfo = useStore.getState().store_userInfo;
  if(!store_userInfo)
  {
    Debug.error('ProjectOps.ShareProject> Invalid user info');
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id)
  {
    Debug.error('ProjectOps.ShareProject> Invalid project');
    return false;
  }

  // If the project is currently "dirty" (not in a saved state), save it first
  if(store_project.isDirty)
    await SaveActiveProject();

  if(user_id === useStore.getState().store_userInfo?.id)
  {
    Debug.error('ProjectOps.ShareProject> Cannot share the project with self');
    return false;
  }

  // Call the server to get the data

  const server = new CallServer();
  server.Add('project_id', store_project.project_id);
  server.Add('destination_user_id', user_id);
  if(user_message)
    server.Add('user_message', user_message);

  const result = await server.Call('post', '/share_project');

  if(result.success)
  {
    Debug.log('ProjectOps.ShareProject> API server call SUCCESS');
    //Debug.log('ProjectOps.ShareProject> SUCCESS! data=' + JSON.stringify(result.data));

    //const shared_project_id: string = result.data;  // Not used

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to share the project")
    Debug.error('ProjectOps.ShareProject> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// The layer states in the project's settings are not updated "live" - they are
// updated just before saving the project.
//-------------------------------------------------------------------------------
function UpdateProjectSettingsLayerStates(project: IProject)
{
  const layers: ILayer[] = useStore.getState().store_layers;
  if(!layers) return;

  const layerStatesArray: IProjectUserSettingsLayerState[] = [];
  for(let i=0; i < layers.length; i++)
  {
    // We only care about active layers
    if(!layers[i].activeInProject) continue;

    const layerState: IProjectUserSettingsLayerState = 
    {
      layer_id: layers[i].id,
      enabled: layers[i].enabled,
      opacity: layers[i].opacity,
      label_opacity: layers[i].vector_layer_label_opacity,
    }

    layerStatesArray.push(layerState);
  }

  const newProjectUserSettingsLayerStates: IProjectUserSettingsLayerStates =
  {
    baseMapLabelsEnabled: useStore.getState().store_baseMapLayer_labels.enabled,
    baseMapStatesEnabled: useStore.getState().store_baseMapLayer_states.enabled,
    baseMapRoadsEnabled: useStore.getState().store_baseMapLayer_roads.enabled,
    layers: layerStatesArray
  }

  project.user_settings.layerStates = newProjectUserSettingsLayerStates;
}

//-------------------------------------------------------------------------------
// Load in the project's active layers in correct order and with the correct 
// states (on/off, opacity, etc).
//
// NOTE: This function must be called AFTER the project AND layer data are
//       already available.
//-------------------------------------------------------------------------------
function LoadProjectSettingsLayerStates(project: IProject)
{
  if(!project || !project.user_settings || !project.user_settings.layerStates || 
     !project.user_settings.layerStates.layers) return;

  // We're going to modify a copy of the layer list, then re-apply it to the state 
  // store after.

  let store_layers: ILayer[] = useStore.getState().store_layers;
  if(!store_layers) return;

  // Clear the map
  // RemoveAllLayersAndSourcesFromMap();
  // RemoveAoiLayerFromMap();
  // RemoveParcelSelectionLayerFromMap();

  // Clear the 'activeInProject' state of all store layers
  for(let i=0; i < store_layers.length; i++)
    store_layers[i].activeInProject = false;

  // Update layer properties based on what is saved in the project settings.  The "active" layers
  // are "added" by simply setting their 'activeInProject' flag to TRUE.
  //
  // The layer order must also be changed to reflect the order in settings.  We move each layer
  // to the end of the layer list one by one to ensure their order relative to one another is adjusted.
  //
  // The actual order of the layers in the full list does not matter much, as the layer library
  // processes the layers into groups and resorts them as needed for display.

  for(let i=0; i < project.user_settings.layerStates.layers.length; i++)
  {
    const layerState: IProjectUserSettingsLayerState = project.user_settings.layerStates.layers[i];

    // Find the layer
    const layer: ILayer | undefined = GetLayerFromListByID(store_layers, layerState.layer_id);
    if(!layer) continue;

    // Update its properties
    layer.activeInProject = true;
    layer.enabled = layerState.enabled;
    layer.opacity = layerState.opacity;
    layer.vector_layer_label_opacity = layerState.label_opacity;

    // Move the layer to the end of the store_layers list (to enforce the layer order saved in the project settings)
    const layerIndex: number | undefined = GetLayerIndex(store_layers, layerState.layer_id);
    if(layerIndex && layerIndex < store_layers.length)
      store_layers = arrayMove(store_layers, layerIndex, store_layers.length);
  }

  // Update the layer list in the state store
  useStore.getState().store_setLayers(store_layers);

  // If any of the active layers are "enabled", add them to the map
  for(let i=0; i < project.user_settings.layerStates.layers.length; i++)
    if(project.user_settings.layerStates.layers[i].enabled)
      AddLayerToMap(project.user_settings.layerStates.layers[i].layer_id, false);
}

//-------------------------------------------------------------------------------
// Checks if the active project has changed db-side since it was last loaded.
// This is only relevant if the project is shared with a group of users.
// 
// A project is considered "changed" if either the AOI group or scenario group
// has changed (ex: another user added a new AOI, deleted an AOI, edited the 
// geometry of an AOI, added a scenario, renamed a scenario, etc).
//
// Called on a timer in the background.
//-------------------------------------------------------------------------------
export async function ReSyncProject(): Promise<boolean>
{
  const store_project: IProject | null = useStore.getState().store_project;
  if(!store_project || !store_project.project_id)
    return false;

  if(!store_project.load_date)
    return false;

  Debug.log('ProjectOps.ReSyncProject>');

  // Call the server to check
  //
  // If the project has not changed, the API call will return nothing.
  //
  // If it HAS changed, the latest full project will be sent back, and the app
  // must determine what has changed and act on it.

  const server = new CallServer();
  server.Add("project_id", store_project.project_id);
  server.Add("last_load_date", store_project.load_date);

  const result = await server.Call('get', '/project');

  if(result.success)
  {
    //Debug.log('ProjectOps.ReSyncProject> API server call SUCCESS');
    //Debug.log('ProjectOps.ReSyncProject> SUCCESS! data=' + JSON.stringify(result.data));
    
    const updatedProject: IProject = result.data;

    if(!updatedProject.project_id)
    {
      // No changes were detected - the active project is still up to date
      //Debug.log(`Projects.ReSyncProject> No changes detected.`);
      return true;  // success
    }

    if(!updatedProject.load_date)
    {
      Debug.error(`ProjectOps.ReSyncProject> Missing load date`);
      return false; // failure
    }

    // Changes were detected

    Debug.log(`Projects.ReSyncProject> CHANGES DETECTED!`);

    // Update the load_date of the active project (otherwise the sync will keep re-detecting this same set of changes)
    useStore.getState().store_setProjectServerSideLoadDate(updatedProject.load_date);

    // Resync AOIs
    await ReSyncProjectAOIs(store_project, updatedProject);

    // Resync scenarios
    await ReSyncProjectScenarios(store_project, updatedProject);

    Debug.log(`Projects.ReSyncProject> Active project resynced.`);
    return true;  // success
  }
  else
  {
    // Failure
    // Disabling this for now - it seems to always trigger after re-logging in following Cognito auth token expiration, which is annoying
    //ToastNotification('error', "Unable to sync project")
    Debug.error(`ProjectOps.ReSyncProject> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Detects AOI changes (add/edit/delete), and updates the live project as needed.
//-------------------------------------------------------------------------------
async function ReSyncProjectAOIs(store_project: IProject, updatedProject: IProject)
{
  // Find out if any AOIs were added or edited

  //const newAoiList: IProjectAoi[] = [];
  //const changedAoiList: IProjectAoi[] = [];
  for(let i=0; i < updatedProject.aois.length; i++)
  {
    const updatedProjectAoi = updatedProject.aois[i];
    
    const existingAoi: IProjectAoi | undefined = store_project.aois.find(findAoi => findAoi.aoi_id === updatedProjectAoi.aoi_id);
    if(!existingAoi)
    {
      Debug.log(`Projects.ReSyncProjectAOIs> NEW AOI ${updatedProjectAoi.aoi_id} (${updatedProjectAoi.aoi_name}) detected`);
      //newAoiList.push(updatedProjectAoi);
      useStore.getState().store_projectAddAoi(updatedProjectAoi);
      ToastNotification('info', 'A new AOI created by another user has been added to the project.');
      continue;
    }

    // Figure out if the AOI has changed
    //
    // For now we simply compare JSON strings.  We don't have the full AOI data for these items (no geometry etc), 
    // but if the item has changed, they will have different last edit dates.

    const oldJSON = JSON.stringify(updatedProjectAoi);
    const newJSON = JSON.stringify(existingAoi);

    if(oldJSON !== newJSON)
    {
      Debug.log(`Projects.ReSyncProjectAOIs> AOI ${updatedProjectAoi.aoi_id} (${updatedProjectAoi.aoi_name}) has changed`);
      //changedAoiList.push(ReSyncProjectAOIs);

      // Update the AOI in the state store
      useStore.getState().store_projectUpdateAoi(updatedProjectAoi);

      // If the "active" AOI has been changed by another user, it must be fully reloaded
      if(existingAoi.aoi_id === useStore.getState().store_aoi?.aoi_id)
      {
        ToastNotification('error', 'The active AOI has been edited by another user and has been reloaded.');
        await LoadAoi(existingAoi.aoi_id, false);
      }
    }
  }

  // Find out if any AOIs were deleted

  //const aoiDeleteList: IProjectAoi[] = [];
  for(let i=0; i < store_project.aois.length; i++)
  {
    const existingAoi = store_project.aois[i];
    const updatedProjectAoi: IProjectAoi | undefined = updatedProject.aois.find(findAoi => findAoi.aoi_id === existingAoi.aoi_id);
    if(!updatedProjectAoi)
    {
      Debug.log(`Projects.ReSyncProjectAOIs> AOI ${existingAoi.aoi_id} (${existingAoi.aoi_name}) no longer exists`);
      //aoiDeleteList.push(existingAoi);

      // Remove the AOI from the list
      useStore.getState().store_projectRemoveAoi(existingAoi.aoi_id);

      // If the "active" AOI is gone, we must either select a new AOI (if one is 
      // available), or if not switch the UI into "create new AOI" mode.

      if(existingAoi.aoi_id === useStore.getState().store_aoi?.aoi_id)
      {
        ToastNotification('error', 'The active AOI has been deleted by another user.');
        
        if(store_project && store_project.aois.length >= 1)
          await LoadAoi(store_project.aois[0].aoi_id);  // There are other AOIs in this project - auto-switch to the first one
        else
        {
          useStore.getState().store_setAoi(null);
          useStore.getState().store_setProjectLastActiveAoi(null);
          useStore.getState().store_setProjectIsDirty(true);
          useStore.getState().store_setAoiUIMode('default');
        }
      }
    }
  }
}

//-------------------------------------------------------------------------------
// Detects scenario changes (add/edit/delete) that have happend in the active 
// project (compared to an updated version freshly loaded from the database).
// Applies any detected changes "live" to the active project to bring it up to date.
//-------------------------------------------------------------------------------
async function ReSyncProjectScenarios(store_project: IProject, updatedProject: IProject)
{
  // Find out if any scenarios were added or edited

  for(let i=0; i < updatedProject.scenarios.length; i++)
  {
    const updatedProjectScenario = updatedProject.scenarios[i];
    
    const existingScrenario: IProjectScenario | undefined = store_project.scenarios.find(findScenario => findScenario.hbv_scenario_id === updatedProjectScenario.hbv_scenario_id);
    if(!existingScrenario)
    {
      Debug.log(`Projects.ReSyncProjectScenarios> NEW scenario ${updatedProjectScenario.hbv_scenario_id} (${updatedProjectScenario.name}) detected`);
      useStore.getState().store_projectAddScenario(updatedProjectScenario);
      ToastNotification('info', 'A new scenario created by another user has been added to the project.');
      continue;
    }

    // Figure out if the scenario has changed
    //
    // For now we simply compare JSON strings.  We don't have the full scenario data for these items, 
    // but we don't need it - if the item has changed, they will have different last edit dates.

    const oldJSON = JSON.stringify(updatedProjectScenario);
    const newJSON = JSON.stringify(existingScrenario);

    if(oldJSON !== newJSON)
    {
      Debug.log(`Projects.ReSyncProjectScenarios> Scenario ${updatedProjectScenario.hbv_scenario_id} (${updatedProjectScenario.name}) has changed`);

      // Update the scenario in the state store
      useStore.getState().store_projectUpdateScenario(updatedProjectScenario);

      // If the "active" screnario has been changed by another user, it must be fully reloaded
      if(existingScrenario.hbv_scenario_id === useStore.getState().store_hbvScenario?.scenario_id)
      {
        ToastNotification('error', 'The active scenario has been edited by another user and has been reloaded.');
        await LoadHbvScenario(existingScrenario);
      }
    }
  }

  // Find out if any scenarios were deleted

  for(let i=0; i < store_project.scenarios.length; i++)
  {
    const existingScenario = store_project.scenarios[i];
    const updatedProjectScenario: IProjectScenario| undefined = updatedProject.scenarios.find(findScenario => findScenario.hbv_scenario_id === existingScenario.hbv_scenario_id);
    if(!updatedProjectScenario)
    {
      Debug.log(`Projects.ReSyncProjectScenarios> Scenario ${existingScenario.hbv_scenario_id} (${existingScenario.name}) no longer exists`);

      // Remove the scenario from the list
      useStore.getState().store_projectRemoveScenario(existingScenario.hbv_scenario_id);

      // If the "active" scenario was deleted, tell the user and close the scenario panel.

      if(existingScenario.hbv_scenario_id === useStore.getState().store_hbvScenario?.scenario_id)
      {
        ToastNotification('error', 'The active scenario has been deleted by another user.');
        useStore.getState().store_setHbvScenario(null);
      }
    }
  }
}

//-------------------------------------------------------------------------------
// Add the special Project Boundary layer to the map.
//-------------------------------------------------------------------------------
export function AddProjectBoundaryLayerToMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Remove any previous project boundary layer from the map (if there is one)
  RemoveProjectBoundaryLayerFromMap();

  // Don't add if we are currently in AOI edit mode
  //if(useStore.getState().store_aoiEditMode) return;

  const store_project = useStore.getState().store_project;
  if(!store_project) return;

  if(!store_project.boundary) return; // The project has no defined boundary - nothing to do

  // If the org's boundary is hidden, stop here
  if(store_project.organization && store_project.organization.settings.show_boundary === false) return;

  // Restrict the map's view to the bounding box of the project boundary
  //store_map.setMaxBounds(turf.bbox(store_project.boundary.boundaryBBox));
  //store_map.fitBounds(turf.bbox(store_project.boundary.boundaryBBox));

  //const paddingFactor = 0.2;

  // [west, south, east, north]
  // store_map.setMaxBounds(
  //   [
  //     store_project.boundary.boundaryBBox[0] - Math.abs(store_project.boundary.boundaryBBox[0]) * paddingFactor, 
  //     store_project.boundary.boundaryBBox[1] - Math.abs(store_project.boundary.boundaryBBox[1]) * paddingFactor, 
  //     store_project.boundary.boundaryBBox[2] + Math.abs(store_project.boundary.boundaryBBox[2]) * paddingFactor, 
  //     store_project.boundary.boundaryBBox[3] + Math.abs(store_project.boundary.boundaryBBox[3]) * paddingFactor,
  //   ]);

// -111.42097141
// 31.414234566
// -97.359861104
// 49.000020967


  // Add the mapbox source

  store_map.addSource('proj-boundary-layer-source',
  {
    type: 'geojson',
    data: store_project.boundary.boundaryGeoJSON,
  })

  // Add mapbox layer 1 (a darker and larger blurry background polygon)

  const newMapboxLayer1: mapboxgl.Layer = 
  {
    'id': 'proj-boundary-layer-1',
    'source': 'proj-boundary-layer-source',
    'type': 'line',
    'paint': 
    {
      'line-color': '#000000',
      'line-width': 10,
      'line-blur': 2,
      //'line-gap-width': 2,
      'line-opacity': 1,
    }
  };

  store_map.addLayer(newMapboxLayer1 as AnyLayer);

  // Add mapbox layer 2 (the thinner cyan main polygon)

  const newMapboxLayer2: mapboxgl.Layer = 
  {
    'id': 'proj-boundary-layer-2',
    'source': 'proj-boundary-layer-source',
    'type': 'line',
    'paint': 
    {
      'line-color': '#D05F61',//'rgba(1,0,0,1)',
      'line-width': 3,
      //'line-blur': 0,
      //'line-gap-width': 2,
      'line-opacity': 1,
    }
  };

  store_map.addLayer(newMapboxLayer2 as AnyLayer);
}

//-------------------------------------------------------------------------------
// Remove the special Project Boundary layer from the map.
//-------------------------------------------------------------------------------
export function RemoveProjectBoundaryLayerFromMap()
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  //[west, south, east, north]
  //store_map.setMaxBounds([-171.791110603, 18.91619, -66.96466, 71.3577635769]); // US+Alaska
  //store_map.setMaxBounds([-124.7844079,24.7433195,-66.9513812,49.3457868]); // Continental US
  //store_map.setMaxBounds([-180,-90,180,90]); // World ? Seems too big

  // Remove the layers

  if(store_map.getLayer('proj-boundary-layer-1'))
    store_map.removeLayer('proj-boundary-layer-1');

  if(store_map.getLayer('proj-boundary-layer-2'))
    store_map.removeLayer('proj-boundary-layer-2');

  // Remove the source
  if(store_map.getSource('proj-boundary-layer-source'))
    store_map.removeSource('proj-boundary-layer-source');
}
